
export var mixinDocs_F = {

  methods: {

    //
    filtrar_records(id= "") {
      // cierro ventana expansible
      this.$store.commit(this.stName + '/data2State', { prop:'rowSelected', value:[] });

      // obtengo todos los registros para filtrarlos
      let records = JSON.parse(JSON.stringify(this.miSt.read_records));

      // si no recibo id, lo actualizo al valor del ctrl
      if (id== "") id= this.schema.ctrls.filter_tipo.value;

      if (id== "0" || records.find(elem=> elem.tpd== id)== undefined) {
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:records });

      }else {
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:records.filter(elem=> elem.tpd== id) });
      }

      // ordeno registros por el campo seleccionado
      this.ordenar_records(this.schema.ctrls.orden.value);
    },


    // ordenar registros por tipo de selección
    ordenar_records(id="") {
      //console.log(id); alert(id);
      // id=> 0:Nombre, 1:Fecha Ascendente, 2:Fecha Descendente
      var records = JSON.parse(JSON.stringify(this.$store.state[this.stName].records));

      if (id=="") id=0; //this.schema.ctrls.filter_tipo.value;

      this.$store.commit(this.stName + '/data2State', { prop:'records', value:records.sort((a, b) => {
        if (Number(id) === 2) {
          if (b[this.itemsTipoDoc_orden[id].c] < a[this.itemsTipoDoc_orden[id].c]) return -1;

        } else {
          if (a[this.itemsTipoDoc_orden[id].c] < b[this.itemsTipoDoc_orden[id].c]) return -1;
        }

        return 0;
      }) });
    },


    //
    async ver_Pdf(evt) {
      var args = { tipo: 'fnc', accion: 'documentos', fn_args: { accion: "fichero", id: evt.row.id, tip: this.tip }};

      // llamada a la API
      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
    },


    // evento cerrar ventana modal
    // compruebo si el mto_XM NO está en edición para poder cerrar la ventana
    cerrar() {
      if (this.miStHijo== null || (this.miStHijo.estado!= 'editar' && this.miStHijo.estado!= 'nuevo')) {
        this.dialog= false;
        return;
      }

      this.$root.$alert.open('GUARDE O CANCELE datos para cerrar', 'info');
    },


    //
    // inicializo datos del filtro y recargo filas del grid
    async recargar() {
      // obtengo registros
      await this.get_records('ini');

      // inicializo tipo de filtro
      if (this.schema.ctrls.filter_tipo.value!= "0") this.filtrar_records(this.schema.ctrls.filter_tipo.value);

      // ordeno registros
      this.ordenar_records(this.schema.ctrls.orden.value);
    },

  }
};
