<!-- Documentos comunes -->

<template>
  <div class="common_Docs">
    <v-dialog v-model="dialog" content-class="modal" persistent>
      <template v-slot:activator="{on}">

        <!-- Botón para abrir Finder Documentos -->
        <v-btn
          v-on='on'
          v-bind="$cfg.btra_cfg.standard"
          v-on:click="recargar"
          v-show="show=='1'"
          title="Documentos">
            <v-icon dark>{{ "mdi-file-document-multiple-outline" }}</v-icon>
        </v-btn>
      </template>


      <div class="contenedor centrado" v-if="schema">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <base_Header
            :Entorno="Entorno.header"
            @onEvent="cerrar()">
          </base_Header>

          <!-- Filtro de Busqueda -->
          <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div style="display:flex; align-items:center">
              <div class="white" style="display:flex">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.orden.value"
                  :label="schema.ctrls.orden.label"
                  :items="itemsTipoDoc_orden"
                  item-value="d"
                  item-text="n"
                  @change="ordenar_records">
                </v-select>

                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.filter_tipo.value"
                  :label="schema.ctrls.filter_tipo.label"
                  :items="$store.state[stName].recordsDetalle[1]"
                  item-value="d"
                  item-text="n"
                  @change="filtrar_records">
                </v-select>
              </div>
            </div>
          </div>

          <!-- Contenido -->
          <div class="conflex">
            <div class="columna" style="width:890px">

              <!-- Grid -->
              <base_Fgrid
                :padre="stName"
                :Entorno="Entorno"
                show-expand
                :items-per-page=-1
                @onEvent="event_Grid">

                  <!-- Slot botonera Top -->
                  <template v-slot:top_btns="{ Sitem }">
                    <div style="padding:10px 0 0 10px;">
                      <btracombi
                        :btra_permisos="schema.btra"
                        :btra_particular="Entorno.btra"
                        btra_modulo="top"
                        @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                      </btracombi>
                    </div>
                  </template>

                  <!-- Slot campo tipo del Grid -->
                  <template v-slot:tipo="{ Sitem }">
                    <b>{{ Sitem.name }}</b> &nbsp;  - &nbsp; <small>{{ Sitem.tipo}}</small>
                    <div v-if="Sitem.des" class="light-blue--text text--darken-3"> {{ Sitem.des }}</div>
                  </template>

                  <!-- Slot acciones del Grid -->
                  <template v-slot:acciones="{ Sitem }">
                    <btracombi
                      :btra_permisos="schema.btra"
                      :btra_particular="Entorno.btra"
                      btra_modulo="mrow"
                      @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
                    </btracombi>
                  </template>

                  <!-- Slot panel expansible -->
                  <template v-slot:expansible_ctrls="{}">
                    <common_docs_XM
                      :padre="stName"
                      :id="id"
                      :accion="accion"
                      :accionRow="rowSelected"
                      :disparo="disparo"
                      :tip="tip"
                      @onEvent="event_expansible">
                    </common_docs_XM>
                  </template>
              </base_Fgrid>
            </div>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
  </div>
</template>



<script>

    import { mixinFinder } from "@/mixins/mixinFinder.js";
    import { mixinDocs_F } from "@/mixins/mixinDocs_F.js";
    import plugs from "@/common/general_plugs";
    const base_Header = () => plugs.groute("base_Header.vue", "base");
    const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
    const btracombi = () => plugs.groute("btracombi.vue", "comp");
    const common_docs_XM = () => plugs.groute("common_docs_XM.vue", "comp");

    export default {
      mixins: [mixinFinder, mixinDocs_F],
      components: { base_Header, base_Fgrid, btracombi, common_docs_XM },
      props: {
        padre: { type:String, default: ''},
        id: { type: [String, Number], default: "" },
        show: { type: String, default: "" },
        tip: { type: String, default: "0" },
        componenteTipo: { type:String, default:'FM' },
        Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
      },

      data() {
        return {
          schema:null,
          api:'common_Docs_F',
          stName:'stFcommon_Docs',
          accion:'',
          accionRow:{},

          headers:{},
          dialog:false,
          disparo:false,
          itemsTipoDoc_orden:[]
        };
      },


      methods: {
        ini_data() {
          console.log("DEV " + this.api + " ********************** (ini_data)");

          // guardo Schema del store en variable local
          this.schema = this.$store.state[this.stName].schema;

          // configuro Entorno
          this.Entorno.header.titulo = "Documentos";
          this.Entorno.grid.row.expansible= true;
          this.Entorno.grid.autoload= false;
          this.Entorno.grid.bind['items-per-page'] = -1;
          this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));
          this.Entorno.btra.row.extra= [
            { accion: "ver_Pdf", btn: "btn_pdf", show: 1 },
          ];

          // header
          this.headers = {
            header: [
                { text: "Tipo documento", value: "tipo", slot:true },
                { text: "Fecha", value: "fhhr", filtro:'fechahr' },
                { text: "Acciones", value: "acciones", sortable: false, width: "auto", slot:true }
              ]
          }

          // establezco cabecera
          this.Entorno.grid.headers = this.set_headers();

          // items adicionales
          // ordenación filas Grid
          this.itemsTipoDoc_orden=[
            { d: "0", n: "Nombre", c: "name" },
            { d: "1", n: "Fecha Ascendente", c: "fhhr" },
            { d: "2", n: "Fecha Descendente", c: "fhhr" }
           ];
        },


        // Pendiente: ¿buscar tip según módulo tabla documentos_tip?
        ini_schemaCtrls() {
          this.schema.ctrls.tip.value= this.tip;
        },


        // configuración particular de los campos del grid
        get_field(item, header) {
          if (header.value === "fhhr") return this.sql2visual({ format:'fhhr' }, item[header.value]);
          return item[header.value];
        },

      }
    };

</script>


